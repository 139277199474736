const light = {
  primary: '#69BE28',
  secondary: '#000000',
  background: '#e5e5e5'
};

const dark = {
  primary: '#69BE28',
  background: '#e5e5e5'
};

export { light, dark };