import AdminGuard from '@/router/guards/AdminGuard.js';

export default {
    path: '',
    beforeEnter: AdminGuard,
    component: () => import('@/templates/Default'),
    children: [
        {
            path: 'user',
            name: 'user.index',
            component: () => import('@/modules/user/views/Table.vue'),
        }, {
            path: 'store',
            name: 'store.index',
            component: () => import('@/modules/store/views/Table.vue'),
        },
    ],
};
