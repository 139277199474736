import { get } from "@/application/api/implementations/app";
import vuetify from './vuetify/index.js';
import Vue from "vue";

const client = {
    install: async (Vue) => {
        const result = await get(`client/${process.env.VUE_APP_CLIENT_ENVIRONMENT}`);
        Vue.prototype.$store.commit('client/setClient', result.data.data);
        vuetify.framework.theme.themes.dark.primary = result.data.data.primaryColor;

        vuetify.framework.theme.themes.light.primary = result.data.data.primaryColor;
        vuetify.framework.theme.themes.light.secondary = result.data.data.secondaryColor;
    }
};

export { client as default };