
const initialState = ({client: null});

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        setClient(state, client) {
            state.client = client;
        },
    },
    getters: {
        getLogoUrl: (state) => state.client?.logo.url,
        getModules: (state) => state.client.modules,
        getFloorPlannerCredentials: (state) => state.client?.floorPlanner
    }
};
