import get from '@/modules/authorisation/api/profile.js';

const initialState = ({token: null, profile: null});

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        setAuthorisationToken(state, token) {
            Object.assign(state, {token});
        },
        resetAuthorisation(state) {
            Object.keys(state).forEach((key) => delete state[key]);
            Object.assign(state, initialState);
        },
        setProfile(state, profile) {
            state.profile = profile;
        },
    },
    actions: {
        logout({commit}) {
            commit('resetAuthorisation');
        },
        async fetchProfile({commit}) {
            const result = await get();
            commit('setProfile', {
                ...result.data.data
            });
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        isAdmin: state => state.profile.isAdmin,
        isApprover: state => state.profile.approver?.length > 0,
        isOrderer: state => state.profile.storeOwner?.length > 0,
        username: (state) => state.profile.name,
        getCurrentClientId: (state) => state.profile?.client?.id,
        getStoreList: (state) => state.profile?.storeOwner,
        getFavouriteStores: (state) => state.profile?.favouriteStores,
        getLocale: (state) => state.profile?.language?.locale,
        getCurrency: (state) => state.profile.language.currency,
        getCurrentUserId: (state) => state.profile?.id
    }
};
