import Vue from 'vue';
import en from 'vuetify/es5/locale/en';
import Vuetify from 'vuetify/lib';
import {dark, light} from './theme';
import icons from '@/plugins/vuetify/icons';

Vue.use(Vuetify);

export default new Vuetify({
    options: {customProperties: true},
    icons,
    lang: {
        locales: {en},
        current: 'en',
    },
    theme: {
        dark: false,
        themes: {
            dark, light
        }
    }
});
