import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authorisation from '@/modules/authorisation/store/authorisation.js';
import error from '@/modules/errorHandling/store/error.js';
import settings from '@/application/store/modules/settings.js';
import shoppingCart from '@/modules/shoppingCart/store';
import configurator from '@/modules/configurator/store'
import client from '@/modules/client/store/client'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: [
                'authorisation',
                'settings'
            ]
        })
    ],
    modules: {
        authorisation,
        settings,
        error,
        shoppingCart,
        configurator,
        client
    },
});
